import React, { Component } from 'react';
// import axios from 'axios';

export class ContactMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromEmail: '',
      EmailBody: '',
      Token: ''
    }
  }

  componentDidMount() {
    this.populateTechStackData();
  }


  async handleSubmit(e) {
    e.preventDefault();

    // console.log(this.state);
      await fetch(`${global.config.url.default}/ContactMe`, {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
      (response) => (response.json())
    ).then((response) => 
    {
      console.log(response)
      // if (response.status === 'success') {
      //   alert("Message Sent.");
      //   this.resetForm()
      // } else if (response.status === 'fail') {
      //   alert("Message failed to send.")
      // }
    })
  }

  onNameChange(event) {
    this.setState({ FromEmail: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ EmailBody: event.target.value })
  }

  async populateTechStackData() {
    const response = await fetch(`${global.config.url.default}/ContactMe`);
    const data = await response.json();
    this.setState({ token: data });
  }

  render() {

    return (
      <div className="contact-me">
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
            <label htmlFor="name" style={{ color: 'white' }}>Email Address</label>
            <input type="text" id="fname" name="firstname" placeholder="Your email address..." value={this.state.FromEmail} onChange={this.onNameChange.bind(this)} />
            <label htmlFor="message" style={{ color: 'white' }}>Message</label>
            <textarea id="subject" name="subject" placeholder="Leave a message..." style={{ height: '200px' }} value={this.state.EmailBody} onChange={this.onMessageChange.bind(this)}/>
          </div>
          <button type="submit" className="button">Submit</button>
        </form>
      </div>
    );
  }
}