import React, { Component } from 'react';
import * as icons from 'react-icons/si';
// import {SiAngular} from 'react-icons/si';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = {
       techStacks: [],
       loading: true,
       testData: [
        {name: "Skill-0", experience: 1, techIcon: "SiCsharp"},
        {name: "Skill-1", experience: 2, techIcon: "SiCsharp"},
        {name: "Skills-2", experience: 3, techIcon: "SiCsharp"},
        {name: "Skills-3", experience: 4, techIcon: "SiCsharp"},
        {name: "Skills-4", experience: 5, techIcon: "SiCsharp"},
        {name: "Skills-5", experience: 6, techIcon: "SiCsharp"},
        {name: "Skills-6", experience: 7, techIcon: "SiCsharp"},
        {name: "Skills-7", experience: 8, techIcon: "SiAngular"},
        {name: "Skills-8", experience: 9, techIcon: "SiCsharp"},
        {name: "Skills-9", experience: 10, techIcon: "SiCsharp"},
        {name: "Skills-10", experience: 11, techIcon: "SiCsharp"}
      ]};
  }

  componentDidMount() {
    this.populateTechStackData();
  }





  static rendertechStacksTable(techStacks) {
    const halfWayIndex = Math.ceil(techStacks.length / 2)

      /* Your icon name from database data can now be passed as prop */
const DynamicFaIcon = ({ name }) => {
  const IconComponent = icons[name];

  if (!IconComponent) { // Return a default one
    return <icons.SiAbbrobotstudio />;
  }

  return <IconComponent />
};

    return (
      <div className='skill-table'>
        <div className='skill-table-col'>
        {techStacks.slice(0,halfWayIndex).map(techStack =>
              <div className="skill-text"> <DynamicFaIcon name={techStack.techIcon}/> {techStack.name}({techStack.experience} {techStack.experience > 1 ? "Years": "Year"})</div>
          )}
        </div>

        <div className='skill-table-col'>
        {techStacks.slice(halfWayIndex).map(techStack =>
              <div className="skill-text"> <DynamicFaIcon name={techStack.techIcon} /> {techStack.name} ({techStack.experience} {techStack.experience > 1 ? "Years": "Year"})</div>
        )}
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? FetchData.rendertechStacksTable(this.state.testData)
      : FetchData.rendertechStacksTable(this.state.techStacks);

    return (
      <div className="skills-container">
        <h1 id="skill-table-name" >Skills</h1>
        <div className="skills-table-container">
              {contents}
        </div>
      </div>
    );
  }

  async populateTechStackData() {
      const response = await fetch(`${global.config.url.default}/Techstack`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include',
    });
    const data = await response.json();
    if(response.status === 200){
        this.setState({ techStacks: data, loading: false });
    }
  }
}

