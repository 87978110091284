

import React  from 'react';
import '../../config'
export class Admin extends React.Component {
    static displayName = Admin.name;
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            isButtonDisabled: false,
            token: '',
            secret: '',
            adminlogin: ''
        };
    }

    componentDidMount() {
        this.generateSecretKey();
        // Cookies.set('_UUID', '12345' , {expires: 1/24, secure: true, sameSite: '-None'})
    }
    
    
    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ isButtonDisabled: true });
        setTimeout(() => {
            this.setState({ isButtonDisabled: false });
        }, 1000);
        const options = {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({
                token: this.state.token,
                password: this.state.password,
                browser: window.clientInformation.userAgent
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const response = await fetch(`${this.state.adminlogin}${this.state.secret}`, options);
        const json = await response.json();
        console.log(json)
        if (json === "Success") {
           window.location.reload()
        }
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    render() {
        const buttonClass = this.state.isButtonDisabled ? "login-form__submit-btn--disabled" : "login-form__submit-btn";
        return (
            <div className="login-container">
                
                <form onSubmit={this.handleSubmit.bind(this)} className="login-form">
                    <div className="login-form__input-container">
                        <label>Secret:</label>
                        <input
                            type="password"
                            value={this.state.password}
                            onChange={this.handlePasswordChange.bind(this)}
                            className="login-form__input"
                        />
                    </div>
                    <button type="submit" className={buttonClass} disabled={this.state.isButtonDisabled}>
                        Submit
                    </button>
                </form>
            </div>
        );
    }

    async generateSecretKey() {
        const response = await fetch(`${global.config.url.default}/Admin/Get`,{
            credentials: 'include'
        });
        const data = await response.json();
        this.setState({ token: data.token, adminlogin: data.adminLogin, secret: data.secret});
    }
}

