import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import Cookies from 'js-cookie'
import {Home} from './components/Home';
import {FetchData} from './components/FetchData';
import {Layout} from './components/Layout';
import {ContactMe} from './components/ContactMe';
import {Index} from './components/Admin/Index';
import {Admin} from "./components/Admin/Admin";


export default class App extends Component {
    render() {
        const somecookie = Cookies.get('SuccessfulLogin')
        
        return (
            <div className="App">
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/fetch-data" element={<FetchData/>}/>
                        <Route path="/contact-me" element={<ContactMe/>}/>
                        <Route exact path="/admin" element={somecookie ? <Index /> : <Admin />} />
                    </Routes>
                </Layout>
            </div>
        );
    }
}
