import React, { Component } from 'react';
import Cookies from 'js-cookie'
import _uniqueId from 'lodash/uniqueId';


export class Index extends Component {
    static displayName = Index.name;

    constructor(props) {
        super(props);
        this.state = {
            techStacks: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateTechStackData();
        // Cookies.remove('_UUID')
        Cookies.set('FrontendCookie', window.crypto.randomUUID() , {expires: 1/24})    
    }



    async populateTechStackData() {
        const response = await fetch('https://localhost:44318/TechStack?includeAdd=true');
        const data = await response.json();
        console.log(response.status)
        if(response.status === 200){
            this.setState({ techStacks: data, loading: false, selectedTech: data[0] });
        }
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTechStackTable(this.state.techStacks);

        return (
            <div className="profile-author">
                <div className="AboutMe" style={{ color: "white" }}>
                    {contents}
                </div>
            </div>
        );
    }

    renderTechStackTable(techStacks) {
        const selectedTech = techStacks.find((tech) => tech.id.toString() === this.state.selectedId);
        const some_val =  this.state.selectedId === '-1' ? 'Add' : 'Update'
        return (
            <div>
                <form onSubmit={(e) => { e.preventDefault(); }}>
                <select onChange={(e) => this.setState({ selectedId: e.target.value })}>
                    <option value="">Please select a skill</option>
                    {techStacks.map((tech) =>
                        <option key={tech.id} value={tech.id}>
                            {tech.name}
                        </option>
                    )}
                </select>
                <div>
 
                    <input type="text" defaultValue={selectedTech ? selectedTech.name : null}  style={{display: this.state.selectedId == '-1' ? 'block': 'none'}} id="tech_name" placeholder="Name" />
                    <input type="text" defaultValue={selectedTech ? selectedTech.experience : null} id="tech_experience" placeholder="Experience" />
                    <input type="text" defaultValue={selectedTech ? selectedTech.techIcon : null} id="tech_icon" placeholder="Icon" />
                </div>

                    <div>
                        
                        {/* <button type="submit" value="Add" style={{display: this.state.selectedId == 'Add' ? 'block': 'none'}}  onClick={(e) => this.handleTechStackUpdate(selectedTech, e.target.value)}>Add</button> */}
                        <button type="submit" value={some_val} onClick={(e) => this.handleTechStackUpdate(selectedTech, e.target.value)}>{some_val}</button>
                        <button type="submit" value="Delete" onClick={(e) => this.handleTechStackUpdate(selectedTech, e.target.value)}>Delete</button>
                    </div>
                </form>
            </div>
        );
    }

    async handleTechStackUpdate(selectedTech, value) {
        selectedTech.experience = document.getElementById('tech_experience').value
        selectedTech.techIcon = document.getElementById('tech_icon').value
        selectedTech.name = document.getElementById('tech_name').value

        let response = await fetch(`https://localhost:44318/Admin/Update?&stat=${value}`, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(selectedTech),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        if(response.status === 401 || response.status === 200){
            window.location.reload()
        }

    }
}
