import React, { Component } from 'react';
import GitHubButton from 'react-github-btn'

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="profile-author">


        <figure className="fir-image-figure">

          <div className="fir-imageover" rel="noopener" target="_blank" href="https://twitter.com/Jonopoly">
            <img className="fir-author-image" src="https://pbs.twimg.com/profile_images/1230224901660909569/nbcUAFci_400x400.jpg" alt="Jon Russell" />
          </div>

          <figcaption>
            <div className="fig-author-figure-title">Jon Russell</div>
            <div className="fig-author-figure-title">Software Engineering Consultant</div>
            <div className="fig-author-figure-title">Liverpool, United Kingdom</div>
          </figcaption>

        </figure>
        <div className="AboutMe" style={{ color: "white" }}>
        Graduated with a first class in Software Engineering in 2018. Working as Junior Developer. Currently working on an open source project in my spare time.
        </div>
        <div>
          <GitHubButton href="https://github.com/Jonopoly/AnonyPy" data-show-count="true" aria-label="Star Jonopoly/AnonyPy on GitHub">AnonyPy</GitHubButton>
        </div>
      </div>

    );
  }
}
