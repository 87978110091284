import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.scss';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <nav>
        <div className="hero">
          <NavLink tag={Link} to="/">Jon Russell</NavLink>
        </div>
        <div className="links" style={{ display: 'flex' }}>
          <NavLink tag={Link} to="/fetch-data">Skills (fetch data)</NavLink>
          <div className="links-right">
            <NavLink tag={Link} to="/contact-me">Get in touch</NavLink>
          </div>
        </div>
      </nav>
    );
  }
}

